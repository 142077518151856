import '../scss/foundation.scss';
import '../scss/main.scss';

// Either import everything
//import 'foundation-sites;

// Or import individual modules
/**/
import 'foundation-sites/dist/js/plugins/foundation.core';
import 'foundation-sites/dist/js/plugins/foundation.dropdownMenu.min.js';
import 'foundation-sites/dist/js/plugins/foundation.util.keyboard.min.js';
import 'foundation-sites/dist/js/plugins/foundation.util.box.min.js';
import 'foundation-sites/dist/js/plugins/foundation.util.nest.min.js';
import 'foundation-sites/dist/js/plugins/foundation.util.mediaQuery';
import 'foundation-sites/dist/js/plugins/foundation.util.imageLoader';
import 'foundation-sites/dist/js/plugins/foundation.util.motion';
import 'foundation-sites/dist/js/plugins/foundation.util.triggers';
import 'foundation-sites/dist/js/plugins/foundation.util.touch';
import 'foundation-sites/dist/js/plugins/foundation.equalizer';
import 'foundation-sites/dist/js/plugins/foundation.tabs';

//import Accordions from './modules/Accordions';

import 'jquery-validation';
import "jquery-validation/dist/additional-methods.js";


// on load
document.addEventListener("DOMContentLoaded", function () {
	$(document).foundation();

	// help block
	// $('.help-block-button').click(function () {
	// 	$('.help-block').addClass('active');
	// });

	// $('body').click(function (e) {
	// 	if ($('.help-block').hasClass('active')) {
	// 		if (!$('.help-block .block-container').is(e.target) && !$('.help-block .help-block-button').is(e.target) && !$('.help-block .block-container h6').is(e.target) && !$('.help-block .block-container a').is(e.target) && !$('.help-block .block-container ul').is(e.target) && !$('.help-block .block-container li').is(e.target)) {
	// 			$('.help-block').removeClass('active');
	// 		}
	// 	}
	// 	else if ($('.chat-block').hasClass('active')) {
	// 		if (!$('.chat-block .block-container').is(e.target) && !$('.chat-block .chat-block-button').is(e.target) && !$('.chat-block .block-container h5').is(e.target) && !$('.chat-block .block-container a').is(e.target)) {
	// 			$('.chat-block').removeClass('active');
	// 		}
	// 	}
	// });

	// accordions
	// const accordion = new Accordions();
	// accordion.init();	

	// form open
	$('.personalform').click(function () {
		$('.form-container').addClass('active');
		$('.personalform-container').removeClass('hide-form');
		$('#personalsuccess').removeClass('show-message');
		$('#personalerror').removeClass('show-message');
		$('#check1').removeClass('valid');
		$('html, body').animate({ scrollTop: $($('.form-container')).offset().top }, '300');
	});

	// form close
	$('#formcancel').click(function () {
		$('.form-container').removeClass('active');
	});

	$('ul.tabs li').click(function () {
		var tab_id = $(this).attr('data-tab');

		$('ul.tabs li').removeClass('current');
		$('.tab-content').removeClass('current');

		$(this).addClass('current');
		$("#" + tab_id).addClass('current');
		$('.map-canvas').removeClass('current');
		$("#map-" + tab_id).addClass('current');

	});

	// back to top button

	var btn = $('#topbutton');
	var fixedblocks = $('.fixed-blocks');

	$(window).scroll(function () {
		if ($(window).scrollTop() > 100) {
			fixedblocks.addClass('show');
		} else {
			fixedblocks.removeClass('show');
		}

		if ($(window).scrollTop() > 300) {
			btn.addClass('show');
		} else {
			btn.removeClass('show');
		}
	});

	btn.on('click', function (e) {
		e.preventDefault();
		$('html, body').animate({ scrollTop: 0 }, '300');
	});

	// file size validator
	$.validator.addMethod('filesize', function (value, element, arg) {
		console.log('file element', element.files.length);
		if(element.files.length > 0){
			if(element.files[0].size<=arg){
				return true;
			}else{
				return false;
			}
		}else{
			return true;
		}
    });


	// personal form validation

	$('#personalform').validate({
		rules: {
			FirstName: {
				required: true
			},
			LastName: {
				required: true
			},
			AlternativeNumber: {
				required: true
			},
			EmailAddress: {
				required: true,
				email: true
			},
			MobileNumber: {
				required: true
			},
			check: {
				required: true
			},
			CV: {
				required: true,
				extension: "pdf|doc|docx",
				filesize: 5000*1024
			}
		},
		messages: {
			CV: {
				extension: "Please upload valid file formats (pdf, doc, docx)",
				filesize: "Please upload a file less than 5Mb"
			}
		},
		onfocusout: function(element) {
			this.element(element);  
		},
		errorPlacement: function(error,element){
			if(element.attr("name") == "check"){
				error.appendTo(".check-error");
			}
			else if(element.attr("name") == "CV"){
				error.appendTo(".cv-error");
			}
			else {
				error.insertAfter(element);
			}
		}		
	});

	$('#personalsubmit').on('click', function () {
		if($("#personalform").valid()){
			$('.personalform-container').addClass('hide-form');
			$('.personalspinner ').addClass('show-spinner');
			$("#personalform").submit();
		}
	});
	


	// apply form validation
	$('#applyform').validate({
		rules: {
			Title: {
				required: true
			},
			FirstName: {
				required: true
			},
			LastName: {
				required: true
			},
			MobileNumber: {
				required: true
			},
			AlternativeNumber: {
				required: true
			},
			EmailAddress: {
				required: true,
				email: true
			},
			Address1: {
				required: true
			},
			// Address2: {
			// 	required: true
			// },
			City: {
				required: true
			},
			County: {
				required: true
			},
			Postcode: {
				required: true
			},
			// CompanyName: {
			// 	required: true
			// },
			// JobTitle: {
			// 	required: true
			// },
			CurrentSalary: {
				number: true
			},
			SalarySought: {
				required: true,
				number: true
			},
			Ethnic: {
				required: true
			},
			Gender: {
				required: true
			},
			Disabled: {
				required: true
			},
			Age: {
				required: true
			},
			Nationality:{
				required: true
			},
			Trans: {
				required: true
			},
			Support: {
				required: true
			},
			Orientation: {
				required: true
			},
			CV: {
				required: true,
				extension: "pdf|doc|docx",				
				filesize: 5000*1024
				
			},
			CoverLetter: {
				extension: "pdf|doc|docx",
				filesize: 5000*1024
			},
			Consent: {
				required: true
			}
		},
		messages: {
			CV: {
				extension: "Please upload valid file formats (pdf, doc, docx)",
				filesize: "Please upload a file less than 5Mb"
			},
			CoverLetter: {
				extension: "Please upload valid file formats (pdf, doc, docx)",
				filesize: "Please upload a file less than 5Mb"
			}
		},
		onfocusout: function(element) {
			this.element(element);  
		},
		errorPlacement: function(error,element){
			if(element.attr("name") == "Ethnic"){
				error.appendTo(".ethnic-error");
			}
			else if(element.attr("name") == "Gender"){
				error.appendTo(".gender-error");
			}
			else if(element.attr("name") == "Disabled"){
				error.appendTo(".disabled-error");
			}
			else if(element.attr("name") == "Age"){
				error.appendTo(".age-error");
			}
			else if(element.attr("name") == "Nationality"){
				error.appendTo(".nationality-error");
			}
			else if(element.attr("name") == "Trans"){
				error.appendTo(".trans-error");
			}
			else if(element.attr("name") == "Support"){
				error.appendTo(".support-error");
			}
			else if(element.attr("name") == "Orientation"){
				error.appendTo(".orientation-error");
			}
			else if(element.attr("name") == "CV"){
				error.appendTo(".cv-error");
			}
			else if(element.attr("name") == "CoverLetter"){
				error.appendTo(".coverletter-error");
			}
			else if(element.attr("name") == "Consent"){
				error.appendTo(".consent-error");
			}
			else {
				error.insertAfter(element);
			}
		}		
	});

	$("#files").change(function () {
		if ($("#files").valid() == true ) {
			$('label[for="files"]').text('Uploaded').addClass('file-uploaded');
		}else{
			$('label[for="files"]').removeClass('file-uploaded');
		}
	});

	$("#coverfiles").change(function () {
		if ($("#coverfiles").valid() == true ) {
			$('label[for="coverfiles"]').text('Uploaded').addClass('file-uploaded');
		}else{
			$('label[for="coverfiles"]').removeClass('file-uploaded');
		}
	});	

	$('#applysubmit').on('click', function () {
		if($("#applyform").valid()){
			$('.applyform-container').addClass('hide-form');
			$('.applyspinner ').addClass('show-spinner');
			$("#applyform").submit();
		} else {
			$('.submit-error ').addClass('show-error');
		}
	});

	$('label a').on('click', function (e) {
		e.stopPropagation();
		e.preventDefault();
		window.open($(this).attr('href'), $(this).attr('target'));
		return false;
	});	

	// accordion
	$('.acc-link').click(function(e) {
		e.preventDefault();
	
	  var $this = $(this);
	
	  if ($this.hasClass('open')) {
		$(this).attr('aria-expanded', 'false');
		$this.removeClass('open');
		  
	  } else {
		$(this).attr('aria-expanded', 'true');		
		$this.toggleClass('open');		  
	  }
  });

});